<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-12">
        <div class="widget">
          <div class="widget-header">
            <div class="row">
              <div class="col-8" />
              <div class="col-4 text-right">
                <div
                  class=""
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    class="btn-group mr-2"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="listMode = true"
                    >
                      <i class="icofont icofont-list" />
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click.prevent="listMode = false"
                    >
                      <i class="las la-chart-bar" />
                    </button>
                    <!-- <button type="button" class="btn btn-secondary">3</button>
                                    <button type="button" class="btn btn-secondary">4</button> -->
                  </div>
                  <!-- <div class="btn-group mr-2" role="group" aria-label="Second group">
                                    <button type="button" class="btn btn-secondary">5</button>
                                    <button type="button" class="btn btn-secondary">6</button>
                                    <button type="button" class="btn btn-secondary">7</button>
                                </div>
                                <div class="btn-group" role="group" aria-label="Third group">
                                    <button type="button" class="btn btn-secondary">8</button>
                                </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="widget-content">
            <div
              class="row"
              v-if="listMode"
            >
              <div class="col-12">
                <data-table
                  :data-source="sortedAbonnement"
                  :headers="headers"
                  table-class="table-striped table-hover"
                >
                  <template slot="actions">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-sm btn-warning"
                      @click.prevent="onlyMyPack = true"
                    > Mes Packs</a>
                    <a
                      href="javascript:void(0)"
                      class="btn btn-sm btn-success"
                      @click.prevent="onlyMyPack = false"
                    > Tout</a>
                  </template>
                </data-table>
              </div>
            </div>
            <div v-else>
              <p>Graph Mode cooming soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/navbar.vue';
import DataTable from '../../components/dataTable/local'
import {mapGetters} from 'vuex'
import moment from 'moment'
import { BOOLEAN_TYPE, COMPONENT_TYPE, DATE_TYPE, NUMERIC_TYPE, TEXT_TYPE } from '../../components/dataTable/dataType';
const Actions = () => import('../../components/pack/abonnement/actions.vue')
const Modules = () =>  import('../../components/pack/abonnement/module.vue')
const Pack = ()  => import('../../components/pack/abonnement/pack.vue')
const Animal = () => import('../../components/sanitaire/animal.vue')
export default {
    components: {Navbar, DataTable},
    data(){
        return {
            navbarItems: [
                {
                    libelle: 'Abonnement'
                }
            ],
            pageIcon: 'la-box',
            pageTitle: 'Les abonnements',
            pageDescription: 'Liste des abonnements',
            listMode: true,
            onlyMyPack: false
        }
    },
    computed: {
        ...mapGetters({
            abonnements: 'packs/abonnements',
            packs: 'packs/packs',
            me: 'auth/me',
            is_super_admin: 'auth/is_super_admin',
        }),
        sortedAbonnement(){
            if(this.onlyMyPack){
                let myPack = [...this.packs].filter(item => item.agent === this.me.uid)
                return [...this.abonnements].filter(abonnement => myPack.map(element => element.uid).includes(abonnement.pack))
                .sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
            }
            return [...this.abonnements].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                {label: 'Pack', name: 'pack', type: COMPONENT_TYPE, component: Pack},
                {label: 'Module', name: 'modules', type: COMPONENT_TYPE, component: Modules},
                {label: 'Prix', name: 'amount', type: NUMERIC_TYPE, sortable: true},
                {label: 'Date abonnement', name: 'createdAt', type: DATE_TYPE, sortable: true},
                {label: 'Dernier renouvel.', name: 'lastRenew', type: DATE_TYPE, sortable: true},
                {label: 'Actif', name: 'actif', type: BOOLEAN_TYPE, sortable: true},
                {label: 'Action', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.widget{
    .widget-content{
        padding-top: 20px
    }
}

</style>